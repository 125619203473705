// views/HomePage.js

import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { Link, useLocation } from 'react-router-dom';
import apiService from '../services/api';
import Container from '../components/Container';
import { shareDeal } from '../components/ShareDeal';
import CategoryHeader from '../components/CategoryHeader';
import { AuthContext } from '../contexts/AuthContext';
import { HomePageContext } from '../contexts/HomePageContext';
import { createSlug } from '../utils/utils';
import { handleVote, handleShareDeal, handleExternalLinkClick, logGoogleEvent } from '../utils/userActions';
import { formatDateFuture } from '../utils/utils.js';
import './HomePage.css';


import viewsIcon from '../assets/global-icons/views.svg';
import deadlineIcon from '../assets/global-icons/deadline.svg';
import locationIcon from '../assets/global-icons/location.svg';
import thumbUpIcon from '../assets/global-icons/thumb_up.svg';
import thumbDownIcon from '../assets/global-icons/thumb_down.svg';
import thumbUpFullIcon from '../assets/global-icons/thumb_up_full.svg';
import thumbDownFullIcon from '../assets/global-icons/thumb_down_full.svg';
import shareIcon from '../assets/global-icons/share.svg';
import externalLinkIcon from '../assets/global-icons/link.svg';
import arrowTopIcon from '../assets/global-icons/arrow-top.svg';

function HomePage({ alertPopup }) {
    const { isAuthenticated, userName, toggleModal } = useContext(AuthContext);
    const { deals, setDeals, skip, setSkip, hasMore, setHasMore, scrollPosition, setScrollPosition } = useContext(HomePageContext);
    const [loading, setLoading] = useState(false);
    const [showBackToTop, setShowBackToTop] = useState(false);
    const limit = 16;
    const loadBeforeReachBottom = 3000;
    const alreadyFetched = useRef(new Set());
    const [categories, setCategories] = useState([]);
    const location = useLocation();
    const [currentCategory, setCurrentCategory] = useState('');
    const [currentSearch, setCurrentSearch] = useState('');
    const [readyToRestoreScroll, setReadyToRestoreScroll] = useState(false);
    const [scrollRestored, setScrollRestored] = useState(false);
    const voteUpButtonRefs = useRef([]);
    const voteDownButtonRefs = useRef([]);
    const { t } = useTranslation(); // Access the translation function
    const { setLogoutCallback } = useContext(AuthContext);

    useEffect(() => {
        voteUpButtonRefs.current = deals.map((_, i) => voteUpButtonRefs.current[i] ?? createRef());
        voteDownButtonRefs.current = deals.map((_, i) => voteDownButtonRefs.current[i] ?? createRef());
    }, [deals]);

    useEffect(() => {
        if (readyToRestoreScroll && !scrollRestored) {
            window.scrollTo(0, scrollPosition);
            setScrollRestored(true);
            setReadyToRestoreScroll(false);
        }
    }, [readyToRestoreScroll, scrollRestored, scrollPosition]);

    useEffect(() => {
        const categoryName = getCategoryFromQuery();
        const searchQuery = getSearchFromQuery();
        setCurrentCategory(categoryName);
        setCurrentSearch(searchQuery);
        if (deals.length === 0) {
            fetchDeals(categoryName, searchQuery);
        }
    }, [location.search]);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        let scrollUpDistance = 0;

        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY < lastScrollY) {
                scrollUpDistance += (lastScrollY - currentScrollY);

                if (scrollUpDistance > 600 && currentScrollY > 0) {
                    setShowBackToTop(true);
                } else {
                    setShowBackToTop(false);
                }
            } else {
                scrollUpDistance = 0;
                setShowBackToTop(false);
            }

            lastScrollY = currentScrollY;

            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - loadBeforeReachBottom && !loading && hasMore) {
                fetchDeals();
            }

            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore, skip, setScrollPosition]);

    const fetchDeals = async (categoryName = currentCategory, searchQuery = currentSearch, reset = false) => {
        if (loading) return;

        setLoading(true);

        //Command to reset parameters if refresh of the page from go to top
        if (reset) {
            setSkip(0);
            setDeals([]);
            alreadyFetched.current.clear();
        }

        const currentSkip = reset ? 0 : skip;

        if (alreadyFetched.current.has(currentSkip)) {
            setLoading(false);
            return;
        }

        alreadyFetched.current.add(currentSkip);

        try {
            const fetchedDeals = await apiService.getAllDeals(limit, currentSkip, categoryName, searchQuery, alertPopup);
            //console.log("Fetched deals:", fetchedDeals);
            setDeals(prevDeals => reset ? fetchedDeals : [...prevDeals, ...fetchedDeals]);
            setHasMore(fetchedDeals.length === limit);

            if (reset) {
                setSkip(limit);
                //console.log(`skip changed to: ${limit}`);
            } else {
                setSkip(prevSkip => {
                    const newSkip = prevSkip + limit;
                    //console.log(`skip changed to: ${newSkip}`);
                    return newSkip;
                });
            }
        } catch (error) {
            alertPopup(t('home_page_fetch_error'), 'error');
        } finally {
            setLoading(false);
            setReadyToRestoreScroll(true);
        }
    };

    const reloadDeals = async () => {
        //console.log('Reloading deals with skip set to 0');
        fetchDeals(currentCategory, currentSearch, true); // Pass true to indicate a reset
    };

    const onVote = (dealId, value) => {
        //console.log("Deal ID", dealId);
        handleVote(dealId, value, setDeals, toggleModal, apiService, isAuthenticated, deals, voteUpButtonRefs, voteDownButtonRefs, alertPopup);
    };
    
    const onShare = (deal) => {
        handleShareDeal(deal, alertPopup, t);
    };
    
    const onLinkClick = (deal) => {
        handleExternalLinkClick(deal);
    };

    const getCategoryFromQuery = () => {
        if (location.pathname.includes('/category')) {
            const query = new URLSearchParams(location.search);
            return query.get('name') || '';
        }
        return '';
    };

    const getSearchFromQuery = () => {
        if (location.pathname.includes('/search')) {
            const query = new URLSearchParams(location.search);
            return query.get('name') || '';
        }
        return '';
    };

    const formatPrice = (price, t) => {
        if (price == null) return "-";
    
        const priceNumber = Number(price);
    
        const formatWithCommas = (num, digits) => {
            const truncated = Math.floor(num * Math.pow(10, digits)) / Math.pow(10, digits);
            return truncated.toString().replace('.', ',');
        };
    
        if (priceNumber >= 1000000000) {
            const billions = formatWithCommas(priceNumber / 1000000000, 3);
            return `${billions}${t('billion')}`; // Translate "tỷ" or "billion"
        } else if (priceNumber >= 1000000) {
            const millions = formatWithCommas(priceNumber / 1000000, 3);
            return `${millions}${t('million')}`; // Translate "tr" or "million"
        }
    
        const formattedPrice = priceNumber.toLocaleString(t('locale'), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    
        return formattedPrice;
    };

    function formatVoteCount(votes) {
        if (Math.abs(votes) >= 10000) {
            return `${Math.round(votes / 1000)}k`;
        } else if (Math.abs(votes) >= 1000) {
            return `${(votes / 1000).toFixed(1)}k`;
        } else {
            return votes.toString();
        }
    }

    const scrollToTopAndReload = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        reloadDeals(); // Call reloadDeals after scrolling to the top
    };

    return (
        <div className="home-page">
            <Container>
                <CategoryHeader categories={categories} />

                {isAuthenticated && userName && (
                    <div className="home-page__user-greeting">
                        Hi, {userName}!
                    </div>
                )}
                <div className="home-page__deal-boxes">
                    {deals.map((deal, index) => (
                        <div key={deal._id} className={`home-page__deal-box ${deal.status === 'Expired' ? 'home-page__deal-box--expired' : ''}`}>
                            {deal.status === 'Expired' && (
                                <Link 
                                    to={`/deal/${createSlug(deal.title)}/${deal._id}`} 
                                    className="home-page__expired-overlay">
                                    onClick={() => logGoogleEvent('navigation', 'Click to dealpage', deal.title, deal._id)}
                                    <div className="home-page__expired-label">{t('home_page_expired')}</div>
                                </Link>
                            )}
                            <Link
                                to={`/deal/${createSlug(deal.title)}/${deal._id}`}
                                className="home-page__deal-link"
                                onClick={() => logGoogleEvent('navigation', 'Click to dealpage', deal.title, deal._id)}
                                style={{ pointerEvents: deal.status === 'Expired' ? 'none' : 'auto' }}
                            >
                                {deal.image1_compressed && (
                                    <div className="home-page__image-container">
                                        <div className="home-page__views-box home-page__views-box--top-left">
                                            <img src={viewsIcon} alt="Views" className="home-page__box-icon" />
                                            <span>{deal.view_counter}</span>
                                        </div>
                                        {deal.end_date !== null && (
                                            <div className="home-page__views-box home-page__views-box--top-right">
                                                <span>{formatDateFuture(deal.end_date, t)}</span>
                                                <img src={deadlineIcon} alt={t('home_page_deadline')} className="home-page__box-icon" />
                                            </div>
                                        )}
                                        <div className="home-page__views-box home-page__views-box--bottom-right" onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            shareDeal(deal.title, deal._id, alertPopup, t);
                                        }}>
                                            <div onClick={() => onShare(deal)}>
                                                <img src={shareIcon} alt="Share" className="home-page__share-icon" />
                                            </div>
                                        </div>
                                        <img src={deal.image1_compressed} alt="Deal Image" className="home-page__deal-image" />
                                    </div>
                                )}
                                <div className="home-page__content">
                                    <div className="home-page__deal-title">{deal.title}</div>
                                    <div className="home-page__price-container">
                                        <div className="home-page__regular-price">
                                            <span className="home-page__regular-currency-symbol">đ</span>
                                            {formatPrice(deal.regular_price, t)}
                                        </div>
                                        <div className="home-page__current-price">
                                            <span className="home-page__current-currency-symbol">đ</span>
                                            {formatPrice(deal.discounted_price, t)}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="home-page__interaction-box">
                                <div className="home-page__vote-container">
                                    <div className="home-page__vote-section">
                                        <button
                                            ref={voteDownButtonRefs.current[index]}
                                            className={`home-page__vote-button thumb-down ${deal.user_vote !== 0 ? 'disabled' : ''}`}
                                            onClick={() => onVote(deal._id, -1)}
                                            aria-label="Vote Down">
                                            <img src={deal.user_vote === -1 ? thumbDownFullIcon : thumbDownIcon} alt="Thumb Down" className="home-page__vote-icon" />
                                        </button>
                                    </div>
                                    <div className="home-page__vote-section">
                                        <span className={deal.vote >= 0 ? 'home-page__vote-positive-count' : ''}>
                                            {formatVoteCount(deal.vote)}
                                        </span>
                                    </div>
                                    <div className="home-page__vote-section">
                                        <button
                                            ref={voteUpButtonRefs.current[index]}
                                            className={`home-page__vote-button thumb-up ${deal.user_vote !== 0 ? 'disabled' : ''}`}
                                            onClick={() => onVote(deal._id, 1)}
                                            aria-label="Vote Up">
                                            <img src={deal.user_vote === 1 ? thumbUpFullIcon : thumbUpIcon} alt="Thumb Up" className="home-page__vote-icon" />
                                        </button>
                                    </div>
                                </div>
                                {deal.link ?
                                    <button onClick={() => onLinkClick(deal)} className="home-page__deal-link-button">
                                        <img src={externalLinkIcon} alt="External Link" />
                                        {t('home_page_view_deal')}
                                    </button>
                                    :
                                    <div className="home-page__deal-link-placeholder"></div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <button className={`back-to-top ${showBackToTop ? 'show' : ''}`} onClick={scrollToTopAndReload}>
                    <img src={arrowTopIcon} className="back-to-top__icon" /> Back to Top
                </button>
            </Container>
        </div>
    );
}

export default HomePage;