//api.js

//const BACKEND_IP = '192.168.96.5'; // Your back-end server's local IP address 192.168.96.4
//const BACKEND_IP = 'localhost'; // Your back-end server's local IP address 192.168.96.4
//const BACKEND_PORT = 5038; // Your back-end server's port

import { t } from 'i18next'; // Importing the translation function
//export const API_URL = `http://${BACKEND_IP}:${BACKEND_PORT}/`;
export const API_URL = process.env.REACT_APP_API_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;


const apiService = {

    async checkServerStatus(alertPopup) {
        try {
            const response = await fetch(API_URL + 'api/status', { credentials: 'include' });
            if (!response.ok) {
                alertPopup(t('api_server_unreachable_error'), 'error');
                throw new Error('Server not responding');
            }
            return true;
        } catch (error) {
            return false; // Server is unreachable
        }
    },
    async getAllDeals(limit, skip, categoryName, searchQuery, alertPopup) {
        let url = API_URL + `api/GetDeals?limit=${limit}&skip=${skip}`;

        if (categoryName) {
            url += `&category=${encodeURIComponent(categoryName)}`;
        }
    
        if (searchQuery) {
            url += `&search=${encodeURIComponent(searchQuery)}`;
        }
    
        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Include credentials for session cookies
            });
    
            if (!response.ok) {
                alertPopup(t('api_error_fetching_deals_from_server'), 'error'); // Translated message
                return [];
            }
    
            const data = await response.json();
            return data;
        } catch (error) {
            alertPopup(t('api_error_fetching_deals'), 'error'); // Translated message
            return [];
        }
    },

    async addDealClick(dealData, alertPopup) {
        const data = new FormData();
    
        for (const key in dealData) {
            if (dealData[key]) { // Only append if there's a value
                data.append(key, dealData[key]);
            }
        }
    
        try {
            const response = await fetch(API_URL + "api/AddDeals", {
                method: "POST",
                body: data,
                credentials: 'include', // if you are handling sessions/cookies
            });

            if (!response.ok) {
                const errorData = await response.json(); // Parse the error response
                // Display validation errors if present
                if (errorData.messageKey) {
                    // Translate and display the error message using the messageKey
                    const translatedMessage = t(errorData.messageKey); 
                    alertPopup(translatedMessage, 'error');
                } else if (errorData.errors && errorData.errors.length > 0) {
                    // Map each error to its translated message
                    const allErrors = errorData.errors.map(error => {
                        const fieldTranslation = t(`error.fieldNames.${error.field}`);
                        return `${t('error.generic', { field: fieldTranslation })}`; // Generic message + translated field
                    });

                    // Join all errors with a newline character and display them together
                    alertPopup(allErrors.join('\n'), 'error');
                } else {
                    // Fallback error message if no errors array is sent from backend
                    alertPopup(t('api_server_refusal'), 'error');
                }
                return null; // Stop further execution
            }
            return await response.json();
        } catch (error) {
            alertPopup(t('api_error_adding_deal'), 'error'); // Translated message
            return null; // Stop propagation of error and return null
        }
    },

    async voteDeal(dealId, value, alertPopup) {
        const voteValue = value === 1 ? 1 : -1; // Set to 1 for thumbUp and -1 for thumbDown
    
        try {
            const response = await fetch(API_URL + "api/voteDeal", {
                method: 'POST',
                credentials: 'include', // Important for sessions
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ dealId, voteValue })
            });
    
            // Check if the response is not ok
            if (!response.ok) {
                alertPopup(t('api_error_voting'), 'error'); // Translated message
                return null; // Stop further execution by returning null
            }

            const data = await response.json();
            return data;

        } catch (error) {
            alertPopup(t('api_error_voting'), 'error'); // Translated message
            return null; // Stop error propagation and return null
        }
    },

    async getDealById(id, alertPopup) {
        try {
            const response = await fetch(`${API_URL}api/GetDealById?id=${id}`, {
                method: 'GET',
                credentials: 'include', // Include credentials for session cookies, if needed
            });
            if (!response.ok) {
                alertPopup(t('api_error_fetching_deal'), 'error'); // Translated message
                return null; // Stop further execution by returning null
            }
            return await response.json();
        } catch (error) {
            alertPopup(t('api_error_fetching_deal'), 'error'); // Translated message
            return null; // Prevent error propagation and return null
        }
    },

    async incrementDealViewCounter(dealId) {
        try {
            const response = await fetch(`${API_URL}api/IncrementDealViewCounter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: dealId })
            });
    
            if (!response.ok) {
                throw null; // Throwing null if the response is not OK
            }
    
        } catch (error) {
            throw null; // Throwing null instead of the original error
        }
    },

    async submitReport(deal_id, report_type, comment, alertPopup) {
        try {
            const response = await fetch(`${API_URL}api/FillDealReport`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ deal_id, report_type, comment })
            });
    
            const data = await response.json(); // Get JSON response only once
    
            if (!response.ok) {
                // Show a generic user-friendly alert
                alertPopup(t('api_error_report_submission'), 'error'); // Translated message
                throw null;
            }
    
        } catch (error) {
            // Show a generic user-friendly alert in case of technical issues
            alertPopup(t('api_general_error'), 'error'); // Translated message
            throw null;
        }
    },

    async checkReportUserExists(dealId, alertPopup) {
        try {
            const response = await fetch(`${API_URL}api/CheckReportUserExists?dealId=${dealId}`, {
                method: 'GET',
                credentials: 'include'
            });
    
            if (!response.ok) {
                alertPopup(t('api_error_checking_report_existence'), 'error'); // Translated message
                return null;  // Return null instead of throwing an error
            }
    
            const result = await response.json();
            return result;
        } catch (error) {
            alertPopup(t('api_error_checking_report_existence'), 'error'); // Translated message
            return null;  // Return null in case of a network or other error
        }
    },

/*********** ADMIN ONLY ***********/

    async getAllDealsAdmin(limit, skip, categoryName, searchQuery, pendingValidationOnly = false) {
        let url = API_URL + `api/GetDealsAdmin?limit=${limit}&skip=${skip}`;

        if (categoryName) {
            url += `&category=${encodeURIComponent(categoryName)}`;
        }

        if (searchQuery) {
            url += `&search=${encodeURIComponent(searchQuery)}`;
        }

        if (pendingValidationOnly) {
            url += `&pendingValidationOnly=true`;
        }

        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Include credentials for session cookies
            });

            if (!response.ok) {
                if (response.status === 403) {
                    throw new Error('Forbidden access');
                } else {
                    throw new Error('Network response was not ok');
                }
            }

            const data = await response.json();
            //console.log("api fetch deals:", data);
            return data;
        } catch (error) {
            throw error; // Propagate the error for further handling
        }
    },

    async deleteDealClick(id) {
        return fetch(API_URL + "api/DeleteDeals?id=" + id, {
            method: "DELETE",
            credentials: 'include', // Add this line to include credentials (cookies)
        }).then(res => res.json());
    },

    //Admin only
    async getAllReportsForDeal(dealId) {
        try {
            const response = await fetch(`${API_URL}api/GetAllReportsForDeal?id=${dealId}`, {
                method: 'GET',
                credentials: 'include', // If you are handling sessions/cookies
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching reports for deal:", error);
            throw error;
        }
    },

    //Admin only
    async updateDealStatus(dealId, dealStatus) {
        try {
            //console.log('Deal id: ', dealId);
            //console.log('Deal status: ', dealStatus);
            const response = await fetch(API_URL + 'api/UpdateDealStatus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ dealId, dealStatus }),
                credentials: 'include', // if you are handling sessions/cookies
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            return await response.json();
        } catch (error) {
            console.error("Error updating deal status:", error);
            throw error;
        }
    }

}

export default apiService;