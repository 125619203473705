// views/DealPage.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../services/api';
import Container from '../components/Container';
import ReportButton from '../components/ReportButton';
import { shareDeal } from '../components/ShareDeal';
import { AuthContext } from '../contexts/AuthContext';
import { HomePageContext } from '../contexts/HomePageContext';
import { handleVote, handleShareDeal, handleExternalLinkClick } from '../utils/userActions';
import Carousel from 'react-bootstrap/Carousel';
import { formatDateFuture, formatDatePast } from '../utils/utils.js';
import { useTranslation } from 'react-i18next';
import './DealPage.css';


import viewsIcon from '../assets/global-icons/views.svg';
import deadlineIcon from '../assets/global-icons/deadline.svg';
import locationIcon from '../assets/global-icons/location.svg';
import thumbUpIcon from '../assets/global-icons/thumb_up.svg';
import thumbDownIcon from '../assets/global-icons/thumb_down.svg';
import thumbUpFullIcon from '../assets/global-icons/thumb_up_full.svg';
import thumbDownFullIcon from '../assets/global-icons/thumb_down_full.svg';
import shareIcon from '../assets/global-icons/share.svg';
import externalLinkIcon from '../assets/global-icons/link.svg';
import shopIcon from '../assets/global-icons/shop.svg';
import startDateIcon from '../assets/global-icons/start_date_dealpage.svg';

const copyToClipboard = (text, alertPopup, t) => {
    navigator.clipboard.writeText(text).then(() => {
      alertPopup(t('deal_page_promo_code_copied'), 'success');
    }).catch(err => {
      alertPopup(t('deal_page_promo_code_copy_failed'), 'error');
    });
};

const formatPrice = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

function DealPage({alertPopup}) {
    const { t } = useTranslation();
    const { isAuthenticated, toggleModal } = useContext(AuthContext);
    const { scrollPosition, setScrollPosition } = useContext(HomePageContext);
    const [deal, setDeal] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const voteUpButtonRef = useRef(null); // Reference for vote up button
    const voteDownButtonRef = useRef(null); // Reference for vote down button

    useEffect(() => {
        fetchDeal(id);
        incrementDealViewCounter(id);
    }, [id]);

    const fetchDeal = async (dealId) => {
      try {
          const fetchedDeal = await apiService.getDealById(dealId, alertPopup);
          if (!fetchedDeal) {
              alertPopup(t('deal_page_fetch_failed'), 'error'); // Display error message
              setTimeout(() => navigate('/'), 3000); // Redirect after 3 seconds
          } else {
              setDeal(fetchedDeal);
          }
      } catch (error) {
          alertPopup(t('deal_page_fetch_error'), 'error'); // Display error message
          setTimeout(() => navigate('/'), 3000);
      }
  };

    const onVote = (dealId, value) => {
      handleVote(
          dealId,
          value,
          (updatedDeals) => setDeal(updatedDeals[0]), // Update the single deal after vote
          toggleModal,
          apiService,
          isAuthenticated,
          [{ ...deal }],  // Wrap deal in an array to simulate the structure in HomePage
          { current: [voteUpButtonRef] }, // Pass a fake ref array for consistency
          { current: [voteDownButtonRef] }, // Pass a fake ref array for consistency
          alertPopup 
      );
    };

    const onShare = () => {
        handleShareDeal(deal, alertPopup, t);
    };

    const onLinkClick = () => {
      handleExternalLinkClick(deal);
    };

    const handleReportSubmit = () => {
        setDeal((prevDeal) => ({
            ...prevDeal,
            user_report_type: 'reported', // Update the report status appropriately
        }));
    };

    const incrementDealViewCounter = async (dealId) => {
        try {
            await apiService.incrementDealViewCounter(dealId);
        } catch (error) {
            //console.error("Failed to increment view counter:", error);
        }
    };

    function formatVoteCount(votes) {
        if (Math.abs(votes) >= 10000) {
            return `${Math.round(votes / 1000)}k`;
        } else if (Math.abs(votes) >= 1000) {
            return `${(votes / 1000).toFixed(1)}k`;
        } else {
            return votes.toString();
        }
    }

    if (!deal) {
        return <div>Loading...</div>;
    }

    const images = [deal.image1, deal.image2, deal.image3].filter(Boolean);
    const percentageReduction = deal.regular_price && deal.discounted_price ? 
        Math.round(((deal.regular_price - deal.discounted_price) / deal.regular_price) * 100) : null;

    return (
        <div className="deal-page">
            <Container isMarginlessOnSmallScreen={true}>
              <div className={deal.status === 'Expired' ? 'deal-page__expired-content' : ''}>
                {deal.status === 'Expired' && <div className="deal-page__expired-overlay"></div>}
      
                <div className="deal-page__deal-box">
                  <div className="deal-page__main-content">
                    <div className="deal-page__image-container">
                      {deal.status === 'Expired' && (
                        <div className="deal-page__expired-header">{t('deal_page_expired')}</div>
                      )}
                      {images.length > 0 ? (
                        <Carousel indicators={true}>
                          {images.map((image, index) => (
                            <Carousel.Item key={index}>
                              <img className="d-block w-100" src={image} alt={t('deal_page_image_alt', { index: index + 1 })} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      ) : (
                        <div className="deal-page__no-image-placeholder">{t('deal_page_no_image')}</div>
                      )}
                    </div>
      
                    <div className="deal-page__summary">
                      <div className="deal-page__view-name-box">
                        <div className="deal-page__view-box">
                          <div className="deal-page__view-item">
                            <img src={viewsIcon} alt={t('deal_page_views')} className="deal-page__summary-icon" />
                            <div className="deal-page__view-item-text">{deal.view_counter} {t('deal_page_views')}</div>
                          </div>
                          {deal.start_date && (
                            <div className="deal-page__view-item">
                              <img src={startDateIcon} alt={t('deal_page_deadline')} className="deal-page__summary-icon" />
                                <div className="deal-page__view-item-text">
                                  {formatDatePast(deal.start_date, t)} {/* Use formatDate for consistent date format */}
                                </div>
                              </div>
                          )}
                          {deal.end_date && (
                            <div className="deal-page__view-item">
                              <img src={deadlineIcon} alt={t('deal_page_deadline')} className="deal-page__summary-icon" />
                                <div className="deal-page__view-item-text">
                                  {formatDateFuture(deal.end_date, t)} {/* Use formatDate for consistent date format */}
                                </div>
                              </div>
                          )}
                          <div className="deal-page__view-item">
                            <img src={locationIcon} alt={t('deal_page_location')} className="deal-page__summary-icon" />
                            <div className="deal-page__view-item-text">{deal.location === 'National' ? t('location.national') : deal.location}</div>
                          </div>
                          {deal.shop && (
                            <div className="deal-page__view-item">
                              <img src={shopIcon} alt={t('deal_page_shop')} className="deal-page__summary-icon" />
                              <div className="deal-page__view-item-text">{deal.shop}</div>
                            </div>
                          )}
                        </div>
                        <div className="deal-page__name-box">
                          <div className="deal-page__date-registering-box">
                            {t('deal_page_added_sentence')}{formatDatePast(deal.date_registering, t)}
                          </div>
                          <div className="deal-page__title">{deal.title}</div>
                          {deal.promo_code && (
                            <div className="deal-page__promo-code" onClick={() => copyToClipboard(deal.promo_code, alertPopup, t)}>
                              {t('deal_page_promo_code')}: <span className="deal-page__promo-code-value">{deal.promo_code}</span>
                            </div>
                          )}
                        </div>
                      </div>
      
                      <div className="deal-page__price-box">
                        {deal.regular_price !== null && deal.regular_price !== undefined && (
                          <div className="deal-page__regular-price">
                            <span className="deal-page__currency-symbol-regular-price">đ</span>
                            {formatPrice(deal.regular_price)}
                          </div>
                        )}
                        {(deal.discounted_price !== null && deal.discounted_price !== undefined) || percentageReduction ? (
                          <div className="deal-page__current-price-container">
                            {deal.discounted_price !== null && deal.discounted_price !== undefined && (
                              <div className="deal-page__current-price">
                                <span className="deal-page__currency-symbol-current-price">đ</span>
                                {formatPrice(deal.discounted_price)}
                              </div>
                            )}
                            {percentageReduction !== null && percentageReduction >= 1 && (
                              <div className="deal-page__reduction">
                                -{percentageReduction}% {t('deal_page_discount')}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
      
                      <div className="deal-page__interaction-box">
                        <div className="deal-page__vote-container">
                          <div className="deal-page__vote-section">
                            <button
                              ref={voteDownButtonRef}
                              className={`deal-page__vote-button thumb-down ${deal.user_vote !== 0 ? 'disabled' : ''}`}
                              onClick={() => onVote(deal._id, -1)}
                              aria-label={t('deal_page_vote_down')}
                            >
                              <img
                                src={deal.user_vote === -1 ? thumbDownFullIcon : thumbDownIcon}
                                alt={t('deal_page_vote_down')}
                                className="deal-page__vote-icon"
                              />
                            </button>
                          </div>
                          <div className="deal-page__vote-section">
                            <span className={deal.vote >= 0 ? 'deal-page__vote-positive-count' : ''}>
                              {formatVoteCount(deal.vote)}
                            </span>
                          </div>
                          <div className="deal-page__vote-section">
                            <button
                              ref={voteUpButtonRef}
                              className={`deal-page__vote-button thumb-up ${deal.user_vote !== 0 ? 'disabled' : ''}`}
                              onClick={() => onVote(deal._id, 1)}
                              aria-label={t('deal_page_vote_up')}
                            >
                              <img
                                src={deal.user_vote === 1 ? thumbUpFullIcon : thumbUpIcon}
                                alt={t('deal_page_vote_up')}
                                className="deal-page__vote-icon"
                              />
                            </button>
                          </div>
                        </div>
                        {deal.link ? (
                          <button onClick={onLinkClick} className="deal-page__deal-link-button">
                            <img src={externalLinkIcon} alt={t('deal_page_external_link')} />
                            {t('deal_page_see_deal')}
                          </button>
                        ) : (
                          <div className="deal-page__deal-link-placeholder"></div>
                        )}
                        <div className="deal-page__share-box" onClick={onShare}>
                          <img src={shareIcon} alt={t('deal_page_share')} className="deal-page__share-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="deal-page__description">
                    <b>
                      {t('deal_page_about_deal')}<br /><br />
                      <div dangerouslySetInnerHTML={{ __html: deal.description }}></div>
                    </b><br />
                    <div className="deal-page__description-buttons">
                      <ReportButton
                        dealId={deal._id}
                        userReportType={deal.user_report_type}
                        alertPopup={alertPopup}
                        onReportSubmit={handleReportSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        );
    }
    
    export default DealPage;